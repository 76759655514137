<template>
  <v-dialog v-model="show" max-width="1500" :fullscreen="$vuetify.breakpoint.xsOnly" persistent>
    <v-card>
      <v-card-title class="card-header-add font-weight-bold px-2 py-2 mb-5">
        <span class="font-size15">{{ $t('patient-daily-measurements-notes') }} {{ patientName }}</span>
        <v-spacer />
        <v-icon color="white" @click="closeDialog">mdi-window-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="5">
            <v-card>
              <v-data-table
                  ref="table"
                  class="elevation-1 pa-2"
                  :items="dailyMedicalNotes"
                  height="510"
                  max-height="510"
                  dense
                  fixed-header
                  :no-data-text="$t('no-data-available')"
                  :loading="loading"
                  :headers="noteHeaders1"
                  group-by="medicalNoteData.id"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :footer-props="footerProps"
                  :items-per-page="-1"
              >
                <template v-slot:[`group.header`]="{ group, headers, items, isOpen, toggle }">
                  <td @click="toggle" class="pa-1 text-left medical-teams-data-table pointer" :colspan="7" style="height:8px !important;">
                    <v-row dense class="my-n1">
                      <v-col>
                        <v-btn small icon :ref="group" :data-open="isOpen" class="color-black">
                          <v-icon x-small v-if="isOpen">mdi-arrow-down</v-icon>
                          <v-icon v-else x-small>mdi-arrow-right</v-icon>
                        </v-btn>
                        <span class="font-weight-bold font-size11">{{ items[0].medicalNoteData.medicalNoteName }}</span>
                      </v-col>
                      <v-col class="d-flex justify-end align-center">
                        <span class="font-weight-bold font-size11 d-flex justify-end my-1 mx-2 ">{{formatDateUsingLocalesFromUTC(items[0].medicalNoteData.timeAdded) }}</span>
                        <v-icon :disabled="!checkIfiCanDeleteMedicalNote(items[0].medicalNoteData)" @click.stop="deleteMedicalNote(items[0].medicalNoteData)" small color="primary">mdi mdi-delete</v-icon>
                      </v-col>
                    </v-row>
                  </td>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="table-header text-left header-cell medical-staff" style=" height:5px !important;">
                      <span class="font-weight-medium font-size11 pl-2">{{ noteHeaders[0].text }}</span>
                    </td>
                    <td class="table-header text-left header-cell medical-staff" style=" height:5px !important;">
                      <span class="font-weight-medium font-size11 pl-3">{{ noteHeaders[1].text }}</span>
                    </td>
                  </tr>
                  <tr v-for="(detail) in item.medicalNoteData.medicalNoteDetails" :key="detail.id">
                    <td class="text-left color-black font-size11 medical-staff-cell pa-0 pl-4" style=" height:5px !important;">
                      {{ medicalStaffName(item.medicalNoteData.createdByMedicalStaffId) }}
                    </td>
                    <td class="text-left pl-4 color-black font-size11 note-detail-cell" style=" height:5px !important;">
                      <div style="max-height:200px; max-width:430px;" class="overflow-auto">
                        <span v-html="formatContent(detail.medicalNoteContent)"></span>
                      </div>
                    </td>
<!--                    <td>-->
<!--                      <v-icon :disabled="!checkIfiCanDeleteAdditionalNote(detail)" small color="primary" @click.stop="deleteUserConfirm(detail)">mdi mdi-delete</v-icon>-->
<!--                    </td>-->
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card>
              <v-data-table
                  :items="measurements"
                  :headers="headers"
                  class="elevation-1 pa-2 pointer"
                  height="510"
                  max-height="510"
                  fixed-header
                  :no-data-text="$t('no-data-available')"
                  :loading-text="$t('loading')"
                  :footer-props="footerProps"
                  :items-per-page="-1"
              >
                <template v-slot:item="{ item }">
                  <tr :class="{'chips-icon': item.isAbnormal}">
                    <td class="font-size13 font-weight-medium text-left d-flex align-center" style="height:25px !important;">
                      <v-img :src="getDeviceIcons(item)" max-width="20"  class="mr-2"></v-img>
                      {{ formatDate(item.measuredAtUtc)  }}
                    </td>
                    <td class="font-size13 font-weight-medium text-left" style="height:25px !important;">
                      {{ formatMeasurement(JSON.parse(item.measuredDataJson)) }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card class="pa-0 ma-0">
              <v-card-text  class="px-0" :key="rerender">
                <v-row dense>
                  <v-col cols="12" class="mt-n4">
                    <v-select
                        class="pa-2"
                        v-model="selectedTemplate"
                        :items="medicalNotesCategoriesForHospital"
                        item-text="medicalNoteCategoryName"
                        item-value="id"
                        :label= "this.$t('Select-template')"
                        outlined
                        dense
                        hide-details
                        auto
                        return-object
                        :menu-props="{ offsetY: true }"
                        @change="getTemplateDescription"
                    >
                      <template v-slot:item="data">
                        <div class="d-flex justify-start align-center">
                          <span class="font-weight-medium font-size15">{{ data.item.medicalNoteCategoryName }}</span>
                        </div>
                      </template>
                    </v-select>

                  </v-col>
                            <!--  TODO: This is hidden with intension to not manipulate the date! DO NOT REMOVE IT because we use the v-model ! ! !  -->
                  <v-col v-if="false" cols="0" class="mt-n4">
                    <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                            class="pa-0 my-4 mr-2"
                            @click="menu = !menu"
                        >
                          mdi-calendar
                        </v-icon>
                      </template>
                      <v-date-picker @change="dateSelected" v-model="calendarDate" @input="menu = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-textarea
                    hide-details
                    dense
                    class="px-2 pb-3 font-size12-5"
                    rows="15"
                    solo
                    no-resize
                    v-model="medicalNote"
                >
                </v-textarea>
                <v-row dense class="mt-n3">
                  <v-col class="d-flex" cols="6" sm="6">
                    <v-card class="d-flex flex-column elevation-0">
                      <div class="py-0 my-0">
                        <span class="font-weight-bold color-black font-size12-5">{{$t('allow-others-to-comment')}}</span>
                        <v-btn-toggle
                            v-model="commentedFromOthers"
                            row
                            active-class="chips white--text"
                            mandatory
                            class="white"
                        >
                          <v-btn
                              x-small
                              :value="true"
                              :input-value="commentedFromOthers"
                          >{{$t("yes")}}</v-btn>
                          <v-btn
                              x-small
                              :value="false"
                              :input-value="commentedFromOthers"
                          >{{$t("no")}}</v-btn>
                        </v-btn-toggle>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col
                      class="d-flex"
                      cols="6"
                      sm="6"
                  >
                    <v-card class="d-flex flex-column elevation-0">
                      <div class="mt-auto">
                        <span class="font-weight-bold color-black font-size12-5">{{$t('allow-others-to-edit')}}</span>
                        <v-btn-toggle
                            v-model="editedFromOthers"
                            row
                            active-class="chips white--text"
                            mandatory
                            class="white"
                        >
                          <v-btn
                              x-small
                              :value="true"
                              :input-value="editedFromOthers"
                          >{{$t("yes")}}</v-btn>
                          <v-btn
                              x-small
                              :value="false"
                              :input-value="editedFromOthers"
                          >{{$t("no")}}</v-btn>
                        </v-btn-toggle>
                      </div>
                      <v-btn :disabled="!selectedTemplate"  small class="float-end ml-auto mt-2 mr-2" color="chips" style="text-transform: capitalize; width:90px; color:white;" :ripple="false" elevation="0" @click="synchronizeDrawers('addNote')">+ {{$t('save')}} </v-btn>
                    </v-card>
                  </v-col>
                </v-row>
<!--                <v-divider class="mt-1"></v-divider>-->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  bloodPressureBlack,
  glucoseMeterBlack,
  monitoringPlanBlack,
  oxymeterBlack,
  scaleBlack,
  thermometerBlack,
  wellnessAssessmentBlack,
} from '@/assets';
import { showErrorAlert, showSuccessAlert } from '@/utils/utilities';

export default {
  name: 'MonthlyReviewDialog',
  data () {
    return {
      wellnessIcon: wellnessAssessmentBlack,
      oximeterIcon: oxymeterBlack,
      glucoseIcon: glucoseMeterBlack,
      bloodPressureIcon: bloodPressureBlack,
      thermometerIcon: thermometerBlack,
      weightScaleIcon: scaleBlack,
      MonitoringPLanBlack: monitoringPlanBlack,
      menu: false,
      onMedication: true,
      calendarDate: new Date().toISOString().slice(0, 10), // Default date
      selectedTemplate: null,
      selectedMedicalNoteCategory: null,
      medicalNote: '',
      rerender: 1,
      selectedTemplateId: 1,
      commentedFromOthers: true,
      editedFromOthers: false,
      medicalNoteEmpty: true,
      isLoading: false,
      billingSecondsForThisMedicalNoteCategory: null,
      medicalNoteCategoryName: null,
      measurementsByDeviceType: {},
      formatedReport: null,
      medicalNoteNameAdditionalData: '',
      medicalNoteStartDate: null,
      medicalNoteEndDate: null,
      assignedHospitalId: null,
      sendMail: false,
      showCheckboxForEmail: false,

      footerProps: { 'items-per-page-options': [-1, 5, 10, 50, 100] },
      dailyMedicalNotes: [],
      sortBy: 'noteTimestamp',
      sortDesc: true,
      search: '',
      loading: false,
      noteHeaders: [
        { text: this.$t('staff'), class: 'table-header height15-black', sortable: false, align: 'start pl-2' },
        { text: this.$t('note-description'), class: 'table-header height15-black', sortable: false, align: 'start' },
      ],
      noteHeaders1: [
        { text: this.$t('MedicalNote'), class: 'table-header height15-black', sortable: false, align: 'start pl-2' },
        { text: this.$t('created'), class: 'table-header height15-black ', sortable: false, align: 'end pr-5' },
      ],
    };
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    patientId: {
      type: Number,
      required: true,
    },
    dialogType: {
      type: String,
      required: true,
    },
    selectedDate: {
      type: String,
      required: true,
    },
    startDate: {
      type: String,
      required: false,
    },
    endDate: {
      type: String,
      required: false,
    },
    patientName: {
      type: String,
      required: true,
    },
    measurements: {
      type: Array,
      required: true,
    },
    medicalNotes: {
      type: Array,
      required: true,
    },
    medicalNoteNewToRPM: {
      type: Array,
      required: true,
    },
    medicalNoteMedicationChange: {
      type: Array,
      required: true,
    },
    measurementsFrom3rdParty: {
      type: Array,
      required: true,
    },
    deviceDefaultsValues: {
      type: Array,
      required: true,
    },
},
  computed: {
    ...mapGetters({
      pageHeight: 'pageHeight',
      hospitalId: 'authentication/getHospitalId',
      userSettings: 'authentication/getUserSettings',
    }),
    ...mapState({
      medicalStaff: (state) => state.hospitals.medicalStaff,
      userData: (state) => state.authentication.userData,
      updateDrawer: (state) => state.updateDrawer,
      filter: (state) => state.filter,
      detailedUser: (state) => state.users.detailedUser,
      hubs: (state) => state.nosessionhubs.hubs,
    }),
    // eslint-disable-next-line vue/return-in-computed-property
    medicalNotesCategoriesForHospital () {
      let allowedTypes = [];

      // Specify the types you want to include based on dialogType
      switch (this.dialogType) {
        case 'DAILY':
          allowedTypes = ['MN-3RDS', 'MN-GENERAL', 'MN-NO-READING', 'MN-ABNORMAL-READING', 'MN-NORMAL-READING', 'MN-DRUGS', 'MN-HOSPITAL'];
          break;
        case 'W1':
        case 'W2':
        case 'W3':
        case 'W4':
          allowedTypes = ['MN-WEEKLY-NORMAL', 'MN-WEEKLY-ABNORMAL'];
          break;
        case 'MONTHLY':
          // allowedTypes = ['MN-MONTHLY', 'MN-NEW-RPM'];
          allowedTypes = ['MN-MONTHLY'];
          break;
          // Add more cases here if needed
        default:
          // Default case if dialogType doesn't match any of the specified cases
          allowedTypes = [];
          break;
      }

      // Filter medical note categories based on allowedTypes
      return this.$store.state.medicalnotecategory.medicalNotesCategoriesForHospital.filter(noteCategory => {
        return allowedTypes.includes(noteCategory.medicalNoteCategoryType);
      });
    },
    headers () {
      return [
        { text: this.$t('time'), value: 'measuredAtUtc', sortable: false, class: 'table-header text-start pl-5' },
        { text: this.$t('measurements'), value: 'measuredDataJson', class: 'table-header text-start pl-4' },
      ];
    },
  },
  mounted () {
    this.calendarDate = this.selectedDate;
    this.getAllMedicalStaff();
    this.assignedHospitalId = localStorage.getItem('selectedHospitalAssignedId');
    this.$store.dispatch('medicalnotecategory/medicalnotecategorytemplateforhospital', this.assignedHospitalId);
  },
  watch: {
    show: {
      handler (newVal) {
        this.calendarDate = this.selectedDate;
        if (newVal) {
          this.assignedHospitalId = localStorage.getItem('selectedHospitalAssignedId');
          this.$store.dispatch('medicalnotecategory/medicalnotecategorytemplateforhospital', this.assignedHospitalId);
        }
      },
      deep: true,
    },
    measurementsFrom3rdParty (measurementsFrom3rdParty) {
      this.measurementsFrom3rdParty = measurementsFrom3rdParty;
      this.updateDailyMedicalNotes();
    },
    medicalNoteMedicationChange (medicalNoteMedicationChange) {
      this.medicalNoteMedicationChange = medicalNoteMedicationChange;
      this.updateDailyMedicalNotes();
    },
    medicalNotes (medicalNote) {
      this.medicalNotes = medicalNote;
      this.updateDailyMedicalNotes();
    },
    medicalNoteNewToRPM (medicalNoteNewToRPM) {
      this.medicalNoteNewToRPM = medicalNoteNewToRPM;
      this.updateDailyMedicalNotes();
    },
  },

  methods: {
    checkIfiCanDeleteMedicalNote (medicalNoteData) {
      return (medicalNoteData.createdByMedicalStaffId === this.userData.id && medicalNoteData.medicalNoteDetails.length < 2);
    },
    checkIfiCanDeleteAdditionalNote (medicalNoteDetailData) {
      return medicalNoteDetailData.medicalStaffId === this.userData.id;
    },
    // async deleteUserConfirm (val) {
    //   await this.$store.dispatch('appointments/deleteAditionalNote', val.id)
    //       .then(res => {
    //         if (res) {
    //           showSuccessAlert(this.$t('success-operation'));
    //         } else {
    //           showErrorAlert(this.$t('failed-operation'));
    //         }
    //
    //         // TODO delete this from local master records
    //         const index = this.dailyMedicalNotes.findIndex(item => item.medicalNoteData.medicalNoteDetails.some(detail => detail.id === val.id));
    //         if (index !== -1) {
    //           const detailIndex = this.dailyMedicalNotes[index].medicalNoteData.medicalNoteDetails.findIndex(detail => detail.id === val.id);
    //           if (detailIndex !== -1) {
    //             this.dailyMedicalNotes[index].medicalNoteData.medicalNoteDetails.splice(detailIndex, 1);
    //           }
    //         }
    //
    //         this.$emit('reloadMontlyReview');
    //       });
    // },

    async deleteMedicalNote (val) {
      const id = val.id;
      try {
        const res = await this.$store.dispatch('appointments/deleteMedicalNote', id);
        if (res) {
          // TODO delete this from local master records
          const index = this.dailyMedicalNotes.findIndex(item => item.medicalNoteData.id === id);
          if (index !== -1) {
            this.dailyMedicalNotes.splice(index, 1);
          }
          showSuccessAlert(this.$t('success-operation'));
          this.$emit('reload-medical-notes');
        } else {
          showErrorAlert(this.$t('failed-operation'));
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          showErrorAlert(error.response.data.msg);
        } else {
          showErrorAlert(this.$t('failed-operation'));
        }
      }
    },

    getDeviceIcons (device) {
      switch (device.deviceType) {
        case 5 :
          return this.weightScaleIcon;
        case 3:
          return this.bloodPressureIcon;
        case 2:
          return this.oximeterIcon;
        case 1:
          return this.thermometerIcon;
        case 6 :
          return this.glucoseIcon;
      }
    },
    updateDailyMedicalNotes () {
      this.dailyMedicalNotes = [];

      if (this.medicalNotes.length > 0) {
        this.dailyMedicalNotes = this.dailyMedicalNotes.concat(this.medicalNotes);
      }

      if (this.measurementsFrom3rdParty.length > 0) {
        this.dailyMedicalNotes = this.dailyMedicalNotes.concat(this.measurementsFrom3rdParty);
      }

      if (this.medicalNoteMedicationChange.length > 0) {
        this.dailyMedicalNotes = this.dailyMedicalNotes.concat(this.medicalNoteMedicationChange);
      }

      if (this.medicalNoteNewToRPM.length > 0) {
        this.dailyMedicalNotes = this.dailyMedicalNotes.concat(this.medicalNoteNewToRPM);
      }
      this.delayExecution(500);
    },
    composeReport (val) {
      switch (val.medicalNoteCategoryType) {
          // Daily medical review Normal reading  DAILY
        case 'MN-NORMAL-READING':
          this.medicalNoteStartDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteEndDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteNameAdditionalData = this.formatDateToUS(this.medicalNoteStartDate.toString());
          this.generateReport(this.medicalNoteStartDate, this.medicalNoteEndDate, val.medicalNoteCategoryType);
          // this.createFormatReportWithMeasurements();
          this.createNormalDailyReview();
          break;
          // Daily medical review Abnormal reading  DAILY
        case 'MN-ABNORMAL-READING':
          this.medicalNoteStartDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteEndDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteNameAdditionalData = this.formatDateToUS(this.medicalNoteStartDate.toString());
          this.generateReport(this.medicalNoteStartDate, this.medicalNoteEndDate, val.medicalNoteCategoryType);
          // this.createFormatReportWithMeasurements();
          this.createAbnormalDailyReview();
          break;
          // Daily medical review No reading DAILY
        case 'MN-NO-READING':
          this.medicalNoteStartDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteEndDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          // this.medicalNoteStartDate = this.firstDayOfSelectedMonth();
          // this.medicalNoteEndDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteNameAdditionalData = this.formatDateToUS(this.medicalNoteStartDate.toString());
          this.generateReport(this.medicalNoteStartDate, this.medicalNoteEndDate, val.medicalNoteCategoryType);
          // this.createFormatReportWithMeasurements();
          // this.createDailyReviewWithMeasurements();
          this.createNoReadingReview();
          break;
          // Weekly medical review  WEEKLY (Normal)
        case 'MN-WEEKLY-NORMAL':
          this.medicalNoteStartDate = this.startDate;
          this.medicalNoteEndDate = this.endDate;
          this.medicalNoteNameAdditionalData = this.formatDateToUS(this.medicalNoteStartDate.toString()) + '-' + this.formatDateToUS(this.medicalNoteEndDate.toString());
          this.generateReport(this.medicalNoteStartDate, this.medicalNoteEndDate, val.medicalNoteCategoryType);
          // this.createFormatReportWithMeasurements();
          this.createWeeklyNormalReview();
          break;
          // Weekly medical review  WEEKLY (Abnormal)
        case 'MN-WEEKLY-ABNORMAL':
          this.medicalNoteStartDate = this.startDate;
          this.medicalNoteEndDate = this.endDate;
          // this.medicalNoteStartDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          // this.medicalNoteEndDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteNameAdditionalData = this.formatDateToUS(this.medicalNoteStartDate.toString()) + '-' + this.formatDateToUS(this.medicalNoteEndDate.toString());
          this.generateReport(this.medicalNoteStartDate, this.medicalNoteEndDate, val.medicalNoteCategoryType);
          // this.createFormatReportWithoutArguments();
          this.createWeeklyAbnormalReview();

          break;
          // Monthly medical review  MONTHLY
        case 'MN-MONTHLY':
          this.medicalNoteStartDate = this.startDate;
          this.medicalNoteEndDate = this.endDate;
          // this.medicalNoteStartDate = this.firstDayOfSelectedMonth();
          // this.medicalNoteEndDate = this.lastDayOfSelectedMonth(new Date(this.calendarDate)); // new Date(this.calendarDate).toISOString().slice(0, 10);
          this.medicalNoteNameAdditionalData = this.formatMonthAndYear(new Date(this.calendarDate.toString()));
          this.generateReport(this.medicalNoteStartDate, this.medicalNoteEndDate, val.medicalNoteCategoryType);
          // this.createFormatReportWithMeasurements();
          this.createMonthlyReview();
          break;

          // Medical Note New to RPM services
        case 'MN-NEW-RPM':
          this.medicalNoteStartDate = this.firstDayOfSelectedMonth();
          this.medicalNoteEndDate = this.lastDayOfSelectedMonth(new Date(this.calendarDate)); // new Date(this.calendarDate).toISOString().slice(0, 10);
          this.medicalNoteNameAdditionalData = this.formatDateToUS(this.medicalNoteStartDate.toString());
          this.generateReport(this.medicalNoteStartDate, this.medicalNoteEndDate, val.medicalNoteCategoryType);
          this.createNewToRpmReview();
          // this.createFormatReportWithoutArguments();
          break;
          // General medical note  DAILY
        case 'MN-GENERAL':
          this.medicalNoteStartDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteEndDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteNameAdditionalData = this.formatDateToUS(this.medicalNoteStartDate.toString());
          this.createFormatReportWithoutArguments();
          break;
          // Measurement read from 3rd party system DAILY
        case 'MN-3RDS':
          this.medicalNoteStartDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteEndDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteNameAdditionalData = this.formatDateToUS(this.medicalNoteStartDate.toString());
          this.generateReport(this.medicalNoteStartDate, this.medicalNoteEndDate, val.medicalNoteCategoryType);
          this.createFormatReportWithoutArguments();
          break;
        case 'MN-DRUGS':
          this.medicalNoteStartDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteEndDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteNameAdditionalData = this.formatDateToUS(this.medicalNoteStartDate.toString());
          this.createFormatReportWithoutArguments();
          break;
          // Medical Note In Hospital
        case 'MN-HOSPITAL':
          this.medicalNoteStartDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteEndDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteNameAdditionalData = this.formatDateToUS(this.medicalNoteStartDate.toString());
          this.createFormatReportWithoutArguments();
          break;
      }
    },
    formatMonthAndYear (date) {
      // Get the year
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      // Return the formatted string
      return `${month}, ${year}`;
    },
    lastDayOfSelectedMonth (date) {
      const today = new Date();
      const year = date.getFullYear();
      const month = date.getMonth();

      // Set the date to the last day of the selected month
      const lastDay = new Date(year, month + 1, 1);

      // Check if today's date is after the last day of the selected month
      if (today > lastDay) {
        // If it is, return the last day of the selected month
        return lastDay.toISOString().slice(0, 10);
      } else {
        // Otherwise, return today's date
        return today.toISOString().slice(0, 10);
      }
    },
    createNormalDailyReview () {
      this.formatedReport = '';

      // Find the selected template
      const selectedTemplate = this.medicalNotesCategoriesForHospital.find(item => item.id === this.selectedTemplate.id);
      this.formatedReport = `${selectedTemplate.medicalNoteCategoryDescription}\n\n`;

      // Initialize default placeholder values
      let tempCounts = '[MEASUREMENT-COUNT]';
      let tempBPMMax = '[BPM-MAX]';
      let tempBPMMin = '[BPM-MIN]';

      tempCounts = 0;
      tempBPMMax = '';
      tempBPMMin = '';

      if (this.measurementsByDeviceType.length > 0 && this.measurementsByDeviceType[3]) {
        // for (const deviceType in this.measurementsByDeviceType) {
        const { count, extremes } = this.measurementsByDeviceType[3];
        tempCounts = count;
        tempBPMMax = extremes.maxValue.readableFormat;
        tempBPMMin = extremes.minValue.readableFormat;
        // }
      }

      // Replace placeholders in the template
      this.formatedReport = this.formatedReport
          .replace('[MEASUREMENT-COUNT]', tempCounts)
          .replace('[BPM-MAX]', tempBPMMax)
          .replace('[BPM-MIN]', tempBPMMin);

      // Assign the final report to the medicalNote
      this.medicalNote = this.formatedReport;
    },
    createAbnormalDailyReview () {
      this.formatedReport = '';

      // Find the selected template
      const selectedTemplate = this.medicalNotesCategoriesForHospital.find(item => item.id === this.selectedTemplate.id);
      this.formatedReport = `${selectedTemplate.medicalNoteCategoryDescription}\n\n`;

      // Initialize default placeholder values
      let tempCounts = '[MEASUREMENT-COUNT]';
      let tempBPMMax = '[BPM-MAX]';
      let tempBPMMin = '[BPM-MIN]';

      tempCounts = 0;
      tempBPMMax = '';
      tempBPMMin = '';

      if (this.measurementsByDeviceType.length > 0 && this.measurementsByDeviceType[3]) { // for (const deviceType in this.measurementsByDeviceType) {
        const { count, extremes } = this.measurementsByDeviceType[3];
        tempCounts = count;
        tempBPMMax = extremes.maxValue.readableFormat;
        tempBPMMin = extremes.minValue.readableFormat;
        // }
      }

      // Replace placeholders in the template
      this.formatedReport = this.formatedReport
          .replace('[MEASUREMENT-COUNT]', tempCounts)
          .replace('[BPM-MAX]', tempBPMMax)
          .replace('[BPM-MIN]', tempBPMMin);

      // Assign the final report to the medicalNote
      this.medicalNote = this.formatedReport;
    },
    createNoReadingReview () {
      this.formatedReport = '';

      // Find the selected template
      const selectedTemplate = this.medicalNotesCategoriesForHospital.find(item => item.id === this.selectedTemplate.id);
      this.formatedReport = `${selectedTemplate.medicalNoteCategoryDescription}\n\n`;

      // Initialize default placeholder values
      let tempCounts = '[MEASUREMENT-COUNT]';
      let tempBPMMax = '[BPM-MAX]';
      let tempBPMMin = '[BPM-MIN]';
      let lastMeasuredDateTime = '[SINCE-DAYS]';

      tempCounts = 0;
      tempBPMMax = '';
      tempBPMMin = '';

      if (this.measurementsByDeviceType.length > 0 && this.measurementsByDeviceType[3]) { // for (const deviceType in this.measurementsByDeviceType) {
        const { count, extremes, measurements } = this.measurementsByDeviceType[3];
        lastMeasuredDateTime = measurements[0].measuredAtLocalTime;
        tempCounts = count;
        tempBPMMax = extremes.maxValue.readableFormat;
        tempBPMMin = extremes.minValue.readableFormat;
      }
      // }

      // Replace placeholders in the template
      this.formatedReport = this.formatedReport
          .replace('[MEASUREMENT-COUNT]', tempCounts)
          .replace('[SINCE-DAYS]', lastMeasuredDateTime)
          .replace('[BPM-MAX]', tempBPMMax)
          .replace('[BPM-MIN]', tempBPMMin);

      // Assign the final report to the medicalNote
      this.medicalNote = this.formatedReport;
    },
    createWeeklyNormalReview () {
      this.formatedReport = '';

      // Find the selected template
      const selectedTemplate = this.medicalNotesCategoriesForHospital.find(item => item.id === this.selectedTemplate.id);
      this.formatedReport = `${selectedTemplate.medicalNoteCategoryDescription}\n\n`;

      // Initialize default placeholder values
      let tempCounts = '[MEASUREMENT-COUNT]';
      let tempBPMMax = '[BPM-MAX]';
      let tempBPMMin = '[BPM-MIN]';
      let lastMeasuredDateTime = '[SINCE-DAYS]';

      tempCounts = 0;
      tempBPMMax = '';
      tempBPMMin = '';

      if (this.measurementsByDeviceType.length > 0 && this.measurementsByDeviceType[3]) { // for (const deviceType in this.measurementsByDeviceType) {
        const { count, extremes, measurements } = this.measurementsByDeviceType[3];
        lastMeasuredDateTime = measurements[0].measuredAtLocalTime;
        tempCounts = count;
        tempBPMMax = extremes.maxValue.readableFormat;
        tempBPMMin = extremes.minValue.readableFormat;
      }
      // }

      // Replace placeholders in the template
      this.formatedReport = this.formatedReport
          .replace('[MEASUREMENT-COUNT]', tempCounts)
          .replace('[SINCE-DAYS]', lastMeasuredDateTime)
          .replace('[BPM-MAX]', tempBPMMax)
          .replace('[BPM-MIN]', tempBPMMin);

      // Assign the final report to the medicalNote
      this.medicalNote = this.formatedReport;
    },
    createWeeklyAbnormalReview () {
      this.formatedReport = '';

      // Find the selected template
      const selectedTemplate = this.medicalNotesCategoriesForHospital.find(item => item.id === this.selectedTemplate.id);
      this.formatedReport = `${selectedTemplate.medicalNoteCategoryDescription}\n\n`;

      // Initialize default placeholder values
      let tempCounts = '[MEASUREMENT-COUNT]';
      let tempBPMMax = '[BPM-MAX]';
      let tempBPMMin = '[BPM-MIN]';
      let lastMeasuredDateTime = '[SINCE-DAYS]';

      tempCounts = 0;
      tempBPMMax = '';
      tempBPMMin = '';

      if (this.measurementsByDeviceType.length > 0 && this.measurementsByDeviceType[3]) { // for (const deviceType in this.measurementsByDeviceType) {
        const { count, extremes, measurements } = this.measurementsByDeviceType[3];
        lastMeasuredDateTime = measurements[0].measuredAtLocalTime;
        tempCounts = count;
        tempBPMMax = extremes.maxValue.readableFormat;
        tempBPMMin = extremes.minValue.readableFormat;
      }
      // }

      // Replace placeholders in the template
      this.formatedReport = this.formatedReport
          .replace('[MEASUREMENT-COUNT]', tempCounts)
          .replace('[SINCE-DAYS]', lastMeasuredDateTime)
          .replace('[BPM-MAX]', tempBPMMax)
          .replace('[BPM-MIN]', tempBPMMin);

      // Assign the final report to the medicalNote
      this.medicalNote = this.formatedReport;
    },
    createMonthlyReview () {
      this.formatedReport = '';

      // Find the selected template
      const selectedTemplate = this.medicalNotesCategoriesForHospital.find(item => item.id === this.selectedTemplate.id);
      this.formatedReport = `${selectedTemplate.medicalNoteCategoryDescription}\n\n`;

      // Initialize default placeholder values
      let tempCounts = '[MEASUREMENT-COUNT]';
      let tempBPMMax = '[BPM-MAX]';
      let tempBPMMin = '[BPM-MIN]';
      let lastMeasuredDateTime = '[SINCE-DAYS]';

      tempCounts = 0;
      tempBPMMax = '';
      tempBPMMin = '';

      if (this.measurementsByDeviceType.length > 0 && this.measurementsByDeviceType[3]) { // for (const deviceType in this.measurementsByDeviceType) {
        const { count, extremes, measurements } = this.measurementsByDeviceType[3];
        lastMeasuredDateTime = measurements[0].measuredAtLocalTime;
        tempCounts = count;
        tempBPMMax = extremes.maxValue.readableFormat;
        tempBPMMin = extremes.minValue.readableFormat;
      }
      // }

      // Replace placeholders in the template
      this.formatedReport = this.formatedReport
          .replace('[MEASUREMENT-COUNT]', tempCounts)
          .replace('[SINCE-DAYS]', lastMeasuredDateTime)
          .replace('[BPM-MAX]', tempBPMMax)
          .replace('[BPM-MIN]', tempBPMMin);

      // Assign the final report to the medicalNote
      this.medicalNote = this.formatedReport;
    },
    createNewToRpmReview () {
      this.formatedReport = '';

      // Find the selected template
      const selectedTemplate = this.medicalNotesCategoriesForHospital.find(item => item.id === this.selectedTemplate.id);
      this.formatedReport = `${selectedTemplate.medicalNoteCategoryDescription}\n\n`;

      // Initialize default placeholder values
      let tempCounts = '[MEASUREMENT-COUNT]';
      let tempBPMMax = '[BPM-MAX]';
      let tempBPMMin = '[BPM-MIN]';
      let firstMeasuredDateTime = '[FIRST-DATE]';

      tempCounts = 0;
      tempBPMMax = '';
      tempBPMMin = '';

      if (this.measurementsByDeviceType.length > 0 && this.measurementsByDeviceType[3]) { // for (const deviceType in this.measurementsByDeviceType) {
        const { count, extremes, measurements } = this.measurementsByDeviceType[3];
        firstMeasuredDateTime = measurements[measurements.length - 1].measuredAtLocalTime;
        tempCounts = count;
        tempBPMMax = extremes.maxValue.readableFormat;
        tempBPMMin = extremes.minValue.readableFormat;
      }
      // }

      // Replace placeholders in the template
      this.formatedReport = this.formatedReport
          .replace('[MEASUREMENT-COUNT]', tempCounts)
          .replace('[FIRST-DATE]', firstMeasuredDateTime)
          .replace('[BPM-MAX]', tempBPMMax)
          .replace('[BPM-MIN]', tempBPMMin);

      // Assign the final report to the medicalNote
      this.medicalNote = this.formatedReport;
    },
    createFormatReportWithoutArguments () {
      this.formatedReport = '';
      // Compile data for each measurement in text form
      const selectedTemplate = this.medicalNotesCategoriesForHospital.find(item => item.id === this.selectedTemplate.id);
      this.formatedReport = `${selectedTemplate.medicalNoteCategoryDescription}\n\n`;
      this.medicalNote = this.formatedReport;
    },
    generateReport (startDate, endDate, medicalNoteCategoryType) {
      this.formatedReport = '';
      // eslint-disable-next-line no-unused-vars
      let filteredData = [];
      // Iterate over each object in assignedMedicalDevicesList array
      filteredData = this.hubs.assignedMedicalDevicesList.map(device => {
        const filteredMeasurements = device.measurementsData
            .filter(measurement => {
              // Check if the measuredAtDate is within the date range
              const measuredDate = new Date(measurement.measuredAtDate.slice(0, 10));
              const start = new Date(startDate);
              const end = new Date(endDate);
              return measuredDate >= start && measuredDate <= end;
            })
            .sort((a, b) => {
              // Convert measuredAtDate strings to Date objects for comparison
              const dateA = new Date(a.measuredAtDate);
              const dateB = new Date(b.measuredAtDate);
              // Sort in descending order based on measuredAtDate
              return dateB - dateA;
            });
        // Parse the measuredDataJson field for each measurement
        filteredMeasurements.forEach(measurement => {
          measurement.measuredData = JSON.parse(measurement.measuredDataJson);

          // This line causing problem
          // delete measurement.measuredDataJson;

          measurement.measuredAtLocalTime = new Date(measurement.measuredAtUtc).toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          });

          // Find the deviceDefaultExtremeValuesJson and deviceDefaultValuesJson for the corresponding deviceType
          const matchingDevice = this.hubs.assignedMedicalDevicesList.find(d => d.deviceType === measurement.deviceType);
          if (matchingDevice) {
            measurement.deviceDefaultExtremeValues = JSON.parse(matchingDevice.deviceDefaultExtremeValuesJson);
            measurement.deviceDefaultValues = JSON.parse(matchingDevice.deviceDefaultValuesJson);
            measurement.deviceName = matchingDevice.deviceName;
            measurement.deviceType = matchingDevice.deviceType;
            measurement.isAbnormal = false;
          }
        });

        // Return the device object with filtered measurementsData
        return {
          deviceName: device.deviceName,
          measurementsData: filteredMeasurements,
        };
      });

      // Merge measurementsData from all devices into one array
      const mergedMeasurements = filteredData.reduce((merged, device) => {
        merged.push(...device.measurementsData);
        return merged;
      }, []);

      // Aggregate measurements by deviceType and count the number of measurements
      this.measurementsByDeviceType = mergedMeasurements.reduce((agg, measurement) => {
        const deviceType = measurement.deviceType;
        if (!agg[deviceType]) {
          agg[deviceType] = {
            deviceName: measurement.deviceName,
            deviceType: measurement.deviceType,
            count: 0,
            extremes: {
              minValue: {
                value: 0,
                readableFormat: '',
                measuredAtUTC: null,
                measuredAtLocalTime: null,
              },
              maxValue: {
                value: 0,
                readableFormat: '',
                measuredAtUTC: null,
                measuredAtLocalTime: null,
              },
            },
            measurements: [],
          };
        }
        agg[deviceType].count++;
        agg[deviceType].measurements.push(measurement);
        return agg;
      }, []);

      this.findMinAndMax();
    },
    formatDateToUS (date) {
      // Split the ISO date into its components
      const [year, month, day] = date.split('-');
      // Rearrange the components to match the US format (MM/DD/YYYY)
      return `${month}/${day}/${year}`;
    },
    firstDayOfCurrentWeek () {
      const tempDate = new Date(this.calendarDate.toString());
      const dayOfWeek = tempDate.getDay();
      const diff = tempDate.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1);
      tempDate.setDate(diff);
      return tempDate.toISOString().slice(0, 10);
    },
    lastDayOfCurrentWeek () {
      const tempDate = new Date(this.calendarDate.toString());
      const dayOfWeek = tempDate.getDay();
      const diff = tempDate.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1) + 6;
      tempDate.setDate(diff);
      return tempDate.toISOString().slice(0, 10);
    },
    firstDayOfSelectedMonth () {
      const tempDate = new Date(this.calendarDate.toString());
      tempDate.setDate(1); // Set the date to the 1st of the current month
      return tempDate.toISOString().slice(0, 10); // Return the date in YYYY-MM-DD format
    },

    formatMeasurementName (deviceType) {
      switch (parseInt(deviceType)) {
        case 1:
          return this.$t('temperature');
        case 2:
          return this.$t('oximetry');
        case 3:
          return this.$t('bloodPressure');
        case 5:
          return this.$t('weight');
        case 6:
          return this.$t('glucometry');
        default:
          return '';
      }
    },
    createDailyReviewWithMeasurements () {
      this.formatedReport = '';

      this.formatedReport = 'Daily review of BP completed, reading today WNL:';
      // Compile data for each measurement in text form
      if (this.measurementsByDeviceType.length > 0 && this.measurementsByDeviceType[3]) {
        for (const deviceType in this.measurementsByDeviceType) {
          const { count } = this.measurementsByDeviceType[deviceType];
          this.formatedReport += `(${count}) `;
        }
      } else {
        this.formatedReport += '0 ';
      }
      this.formatedReport += 'Will continue to monitor trends and update PCP as needed.';
      this.medicalNote = this.formatedReport;
    },
    getTemplateDescription (val) {
      this.selectedMedicalNoteCategory = this.medicalNotesCategoriesForHospital.find(item => item.id === val.id);

      this.composeReport(val);
      // // this.medicalNote = selectedTemplate?.medicalNoteCategoryDescription;
      // this.selectedTemplateId = selectedTemplate.id;
      // this.billingSecondsForThisMedicalNoteCategory = selectedTemplate.billingSecondsForThisMedicalNoteCategory;
      // this.medicalNoteCategoryName = selectedTemplate.medicalNoteCategoryName;
    },
    dateSelected (val) {
      if (this.selectedTemplate === null || this.selectedTemplate === undefined) {
        return;
      }
      this.composeReport(this.selectedTemplate);
    },
    createFormatReportWithMeasurements () {
      this.formatedReport = '';
      // Compile data for each measurement in text form
      const selectedTemplate = this.medicalNotesCategoriesForHospital.find(item => item.id === this.selectedTemplate.id);
      this.formatedReport = `${selectedTemplate.medicalNoteCategoryDescription}\n\n`;
      if (this.measurementsByDeviceType.length > 0) {
        for (const deviceType in this.measurementsByDeviceType) {
          const { count, extremes, measurements } = this.measurementsByDeviceType[deviceType];
          this.formatedReport += `${this.formatMeasurementName(deviceType)}: `;
          this.formatedReport += `counts:(${count}) `;
          this.formatedReport += `max: ${extremes.maxValue.readableFormat} `;
          this.formatedReport += `min: ${extremes.minValue.readableFormat}\n`;
          measurements.forEach((measurement, index) => {
            this.formatedReport += `${index + 1}. `;
            this.formatedReport += `${measurement.measuredAtLocalTime} `;
            this.formatedReport += `measured: ${this.formatMeasurement(measurement.measuredData, measurement.deviceDefaultValues)}\n`;
          });
          this.formatedReport += '\n';
        }
      } else {
        this.formatedReport += 'There are no readings for this patient\n';
      }
      this.medicalNote = this.formatedReport;
    },
    createFormatReportWithoutMeasurements () {
      this.formatedReport = '';
      // Compile data for each measurement in text form
      const selectedTemplate = this.medicalNotesCategoriesForHospital.find(item => item.id === this.selectedTemplate.id);
      this.formatedReport = `${selectedTemplate.medicalNoteCategoryDescription}\n\n`;
      this.medicalNote = this.formatedReport;
    },
    getAllMedicalStaff () {
      this.$store.dispatch('hospitals/getRelatedMedicalStaff', this.userData.hospitalAssignedData.id);
    },
    formatDateUsingLocalesFromUTC (dateTime) {
      return new Date(dateTime).toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
    },
    delayExecution (timeDelay) {
      setTimeout(() => {
        this.toggleHeaders();
      }, timeDelay); // 500 milliseconds = 0.5 seconds
    },
    toggleHeaders () {
      const table = this.$refs.table;
      if (table) {
        const openCache = table?.$vnode.componentInstance.openCache;
        if (openCache) {
          Object.keys(openCache).forEach(group => {
            this.$set(openCache, group, false);
          });
        }
      }
    },
    formatContent (content) {
      return content.replace(/\n/g, '<br>');
    },
    medicalStaffName (staffId) {
      if (this.medicalStaff && this.medicalStaff.length > 0) {
        const matchedStaff = this.medicalStaff.find(staff => staff.id === staffId);
        if (matchedStaff) {
          return `${matchedStaff.firstName} ${matchedStaff.lastName}`;
        }
      }
      return '';
    },
    formatDate (val) {
      // Parse the input date string
      const dateObject = new Date(val);
      // Format the date as "Month Day Year"
      return dateObject.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true });
    },
    getDeviceNames (device) {
      switch (device.deviceType) {
        case 5 :
          return this.$t('weight-scale');
        case 3:
          return this.$t('blood-pressure');
        case 2:
          return this.$t('oximeter');
        case 1:
          return this.$t('thermometer');
        case 6 :
          return this.$t('glucose-meter');
      }
    },
    async synchronizeDrawers () {
      const payloadBody = {
        medicalNoteName: this.selectedMedicalNoteCategory.medicalNoteCategoryName + ': ' + this.medicalNoteNameAdditionalData,
        // medicalNotesCategories: this.medicalNotesCategoriesForHospital,
        // allowToBeEditedFromOthers: false,
        // allowToBeCommentedFromOthers: false,
        medicalNoteCategoryId: this.selectedMedicalNoteCategory.id,
        medicalNoteOfficeId: localStorage.getItem('selectedHospitalAssignedId'),
        createdByMedicalStaffId: this.userData.id,
        medicalNoteForPatientId: this.patientId,
        medicalNoteStartDate: this.medicalNoteStartDate,
        medicalNoteEndDate: this.medicalNoteEndDate,
        allowToBeEditedFromOthers: this.editedFromOthers,
        allowToBeCommentedFromOthers: this.commentedFromOthers,
        medicalNoteDetail: {
          // medicalNoteId: 0,
          // billingSecondsForThisMedicalNoteDetail: this.selectedMedicalNoteCategory.billingSecondsForThisMedicalNoteCategory,
          medicalStaffId: this.userData.id,
          medicalNoteContent: this.medicalNote,
          medicalNoteAdditionalData: '',
        },
      };
      await this.$store.dispatch('appointments/addMedicalNote', payloadBody).then(res => {
          this.closeDialog();
      });

      // // TODO if we dont want to iterate through measurements data we can remove this endpoint
      // const data = {
      //   id: this.patientId,
      //   filter: this.$cookies.get('datafilter'),
      // };
      // await this.$store.dispatch('nosessionhubs/getHubsData', data);
      //
      // this.$store.commit('SET_PATIENT_DETAILS_DRAWER', drawer);
      // this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
      //   updateDrawer: true,
      //   page2: 'patientdetails',
      //   filter: this.dialogType.toUpperCase(),
      //   selectedDate: this.selectedDate,
      //   patientId: this.patientId,
      // });
      // this.closeDialog();
    },
    getDeviceDefaults (deviceType) {
      return this.deviceDefaultsValues.find(device => device.deviceType === deviceType);
    },
    formatMeasurement (data) {
      // const data = JSON.parse(values);
      var deviceDefaults = JSON.parse(this.getDeviceDefaults(parseInt(data.device_type)).deviceDefaultValuesJson);
      var isAbnormal = false;
      switch (parseInt(data.device_type)) {
        case 1:
          isAbnormal = false;
          if (data?.Temperature_C > deviceDefaults.MaxTemperature || data?.Temperature_C < deviceDefaults.MinTemperature) {
            isAbnormal = true;
          } else {
            isAbnormal = false;
          }

          if (this.userSettings.Units.toLowerCase() === 'metric') {
            if (isAbnormal) {
              return data?.Temperature_C + ' °C (Abnormal)';
            } else {
              return data?.Temperature_C + ' °C (Normal)';
            }
          } else if (this.userSettings.Units.toLowerCase() === 'imperial') {
            if (isAbnormal) {
              return data?.Temperature_F + ' °F (Abnormal)';
            } else {
              return data?.Temperature_F + ' °F (Normal)';
            }
          }
          break;
        case 3:
          isAbnormal = false;

          var systolicChecking = data?.Sys > deviceDefaults.SystolicMax || data?.Sys < deviceDefaults.SystolicMin;
          var diastolicChecking = data?.Dias > deviceDefaults.DiastolicMax || data?.Dias < deviceDefaults.DiastolicMin;
          var heartBeatChecking = data?.Pul > deviceDefaults.HeartBeatMax || data?.Pul < deviceDefaults.HeartBeatMin;

          if (systolicChecking || diastolicChecking || heartBeatChecking) {
            isAbnormal = true;
            return data?.Sys + '/' + data?.Dias + ' mmHg (Abnormal)';
          } else {
            isAbnormal = false;
            return data?.Sys + '/' + data?.Dias + ' mmHg (Normal)';
          }

        case 2:
          isAbnormal = false;

          if (data?.Spo2 < deviceDefaults.SpO2) {
            isAbnormal = true;
            return data?.Spo2 + '% (Abnormal)';
          } else {
            isAbnormal = false;
            return data?.Spo2 + '% (Normal)';
          }
        case 5:
          if (this.userSettings.Units.toLowerCase() === 'metric') {
            return data.weight_kg + ' kg';
          } else return data.weight_lbs + ' lb';
        case 6:
          //  isMeasuredBeforeMeal
          isAbnormal = false;

          var glucoseChecking = data?.glucose_mmolL > deviceDefaults.BloodSugarLevelMax || data?.glucose_mmolL < deviceDefaults.BloodSugarLevelMin;
          if (glucoseChecking) {
            isAbnormal = true;
          } else {
            isAbnormal = false;
          }

          if (isAbnormal) {
            if (this.userSettings.Concentration.toLowerCase() === 'mmol/l') {
              return parseFloat(data.glucose_mmolL).toFixed(1) + ' mmol/L (Abnormal)';
            } else return parseFloat(data.glucose_mgL).toFixed(1) + ' mg/dL (Abnormal)';
          } else {
            if (this.userSettings.Concentration.toLowerCase() === 'mmol/l') {
              return parseFloat(data.glucose_mmolL).toFixed(1) + ' mmol/L (Normal)';
            } else return parseFloat(data.glucose_mgL).toFixed(1) + ' mg/dL (Normal)';
          }
        case 7:
          if (this.userSettings.Concentration.toLowerCase() === 'mmol/l') {
            return parseFloat(data.cholesterol_mmolL).toFixed(1) + ' mmol/L';
          } else return parseFloat(data.cholesterol_mgL).toFixed(1) + ' mg/dL';
      }
    },
    findMinAndMax () {
      this.measurementsByDeviceType.forEach(deviceMeasurements => {
        const tempExtremes = {
          minValue: {
            value: Infinity,
            readableFormat: '',
            measuredAtUTC: null,
            measuredAtLocalTime: null,
          },
          maxValue: {
            value: -Infinity,
            readableFormat: '',
            measuredAtUTC: null,
            measuredAtLocalTime: null,
          },
        };

        deviceMeasurements.measurements.forEach(measurement => {
          const { deviceType, measuredData, measuredAtUtc, measuredAtLocalTime } = measurement;
          var range = measurement.deviceDefaultValues;

          switch (parseInt(deviceType)) {
            case 1: // Temperature device
              // eslint-disable-next-line no-case-declarations
              const tempValue = this.userSettings.Units.toLowerCase() === 'metric' ? measuredData.Temperature_C : measuredData.Temperature_F;
              // eslint-disable-next-line no-case-declarations
              const tempUnit = this.userSettings.Units.toLowerCase() === 'metric' ? '°C' : '°F';

              if (measuredData.Temperature_C > range.MaxTemperature || measuredData.Temperature_C < range.MinTemperature) {
                measuredData.isAbnormal = true;
                measurement.isAbnormal = true;
              } else {
                measuredData.isAbnormal = false;
                measurement.isAbnormal = false;
              }

              if (measuredData.Temperature_C < tempExtremes.minValue.value) {
                tempExtremes.minValue = {
                  value: tempValue,
                  readableFormat: tempValue + tempUnit,
                  measuredAtUTC: measuredAtUtc,
                  measuredAtLocalTime: measuredAtLocalTime,
                };
              }

              if (measuredData.Temperature_C > tempExtremes.maxValue.value) {
                tempExtremes.maxValue = {
                  value: tempValue,
                  readableFormat: tempValue + tempUnit,
                  measuredAtUTC: measuredAtUtc,
                  measuredAtLocalTime: measuredAtLocalTime,
                };
              }
              break;

            case 2: // SPO2 device
              if (measuredData.Spo2 < range.SpO2) {
                measuredData.isAbnormal = true;
                measurement.isAbnormal = true;
              } else {
                measuredData.isAbnormal = false;
                measurement.isAbnormal = false;
              }

              if (measuredData.Spo2 < tempExtremes.minValue.value) {
                tempExtremes.minValue = {
                  value: measuredData.Spo2,
                  readableFormat: measuredData.Spo2 + '%',
                  measuredAtUTC: measuredAtUtc,
                  measuredAtLocalTime: measuredAtLocalTime,
                };
              }

              if (measuredData.Spo2 > tempExtremes.maxValue.value) {
                tempExtremes.maxValue = {
                  value: measuredData.Spo2,
                  readableFormat: measuredData.Spo2 + '%',
                  measuredAtUTC: measuredAtUtc,
                  measuredAtLocalTime: measuredAtLocalTime,
                };
              }
              break;

            case 3: // Blood pressure device (Sys)
              var systolicChecking = measuredData.Sys > range.SystolicMax || measuredData.Sys < range.SystolicMin;
              var diastolicChecking = measuredData.Dias > range.DiastolicMax || measuredData.Dias < range.DiastolicMin;
              var heartBeatChecking = measuredData.Pul > range.HeartBeatMax || measuredData.Pul < range.HeartBeatMin;
              if (systolicChecking || diastolicChecking || heartBeatChecking) {
                measuredData.isAbnormal = true;
                measurement.isAbnormal = true;
              } else {
                measuredData.isAbnormal = false;
                measurement.isAbnormal = false;
              }
              if (measuredData.Sys < tempExtremes.minValue.value) {
                tempExtremes.minValue = {
                  value: measuredData.Sys,
                  readableFormat: measuredData.Sys + '/' + measuredData.Dias + 'mmHg',
                  measuredAtUTC: measuredAtUtc,
                  measuredAtLocalTime: measuredAtLocalTime,
                };
              }

              if (measuredData.Sys > tempExtremes.maxValue.value) {
                tempExtremes.maxValue = {
                  value: measuredData.Sys,
                  readableFormat: measuredData.Sys + '/' + measuredData.Dias + 'mmHg',
                  measuredAtUTC: measuredAtUtc,
                  measuredAtLocalTime: measuredAtLocalTime,
                };
              }
              break;
            case 5: // Weight scale
              measuredData.isAbnormal = false;
              measurement.isAbnormal = false;
              // eslint-disable-next-line no-case-declarations
              const scaleValue = this.userSettings.Units.toLowerCase() === 'metric' ? measuredData.weight_kg : measuredData.weight_lbs;
              // eslint-disable-next-line no-case-declarations
              const scaleUnit = this.userSettings.Units.toLowerCase() === 'metric' ? 'Kg' : 'lbs';

              if (scaleValue < tempExtremes.minValue.value) {
                tempExtremes.minValue = {
                  value: scaleValue,
                  readableFormat: scaleValue + scaleUnit,
                  measuredAtUTC: measuredAtUtc,
                  measuredAtLocalTime: measuredAtLocalTime,
                };
              }

              if (scaleValue > tempExtremes.maxValue.value) {
                tempExtremes.maxValue = {
                  value: scaleValue,
                  readableFormat: scaleValue + scaleUnit,
                  measuredAtUTC: measuredAtUtc,
                  measuredAtLocalTime: measuredAtLocalTime,
                };
              }
              break;

            case 6: // Blood sugar
              // eslint-disable-next-line no-case-declarations
              const bloodSugarValue = this.userSettings.Concentration.toLowerCase() === 'mmol/l' ? measuredData.glucose_mmolL : measuredData.glucose_mgL;
              // eslint-disable-next-line no-case-declarations
              const bloodSugarUnit = this.userSettings.Concentration.toLowerCase() === 'mmol/l' ? 'mmol/L' : 'mg/dL';

              var glucoseChecking = measuredData.glucose_mmolL > range.BloodSugarLevelMax || measuredData.glucose_mmolL < range.BloodSugarLevelMin;
              if (glucoseChecking) {
                measuredData.isAbnormal = true;
                measurement.isAbnormal = true;
              } else {
                measuredData.isAbnormal = false;
                measurement.isAbnormal = false;
              }

              if (bloodSugarValue < tempExtremes.minValue.value) {
                tempExtremes.minValue = {
                  value: bloodSugarValue.toFixed(1),
                  readableFormat: bloodSugarValue.toFixed(1) + bloodSugarUnit,
                  measuredAtUTC: measuredAtUtc,
                  measuredAtLocalTime: measuredAtLocalTime,
                };
              }

              if (bloodSugarValue > tempExtremes.maxValue.value) {
                tempExtremes.maxValue = {
                  value: bloodSugarValue.toFixed(1),
                  readableFormat: bloodSugarValue.toFixed(1) + bloodSugarUnit,
                  measuredAtUTC: measuredAtUtc,
                  measuredAtLocalTime: measuredAtLocalTime,
                };
              }
              break;

            default:
              // Handle any other device types if necessary
              break;
          }
        });
        // Assign the computed tempExtremes to the extremes property of the current deviceMeasurements
        deviceMeasurements.extremes = tempExtremes;
      });
    },
    closeDialog () {
      this.selectedTemplate = null;
      this.medicalNote = '';
      this.$emit('reload-medical-notes');
      this.$emit('update:show', false);
    },
  },
};
</script>
